#root .appBar {
  z-index: 1201;
}

#root .appBar .appBarTitle {
  flex-grow: 1;
  padding-left: 16px;
}

#root .appBar .appBarButton {
  margin-right: 8px;
}

#root .appBar .toolbar {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

#root .appBar .toolbar .menuButton {
  margin-left: 0px;
  margin-right: 12px;
}

#root .drawer {
  height: 100%;
  width: 275px;
  flex-shrink: 0;
  overflow-x: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 2;
}

#root .drawer .drawerHead {
  min-height: 64px;
}

#root .drawer .teamListItem.selected {
  border-right: 4px solid #6001d2;
  /* background-color: rgba(0, 0, 0, 0.08); */
  /* background-color: rgba(96, 1, 210, 0.08);
  background-color: rgba(1, 121, 255, 0.08);
  background-color: rgba(152, 68, 255, 0.08); */
}

#root .drawer .teamListItem.disabled .teamListItemAvatar,
#root .drawer .teamListItem.disabled .teamListItemText {
  opacity: 0.5;
}

#root .drawer .teamListItemText {
  padding-right: 0;
}

#root .drawer .teamListItemText > p {
  /* max-width: 150px; */
}

#root .drawer .teamLinkButton {
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
}

#root .drawer .teamListHeadingText {
  text-align: center;
}

#root .drawer .leagueYearText {
  color: rgba(0, 0, 0, 0.54);
}

#root .drawer .paper {
  width: 275px;
  position: relative;
  overflow-x: hidden;
}

#root .drawer .paper.close {
  white-space: nowrap;
}

#root .drawer.open {
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 275px;
}

#root .drawer.close {
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow-x: hidden;
  width: 73px;
}

#root .statTotalsExpansionPanel {
  margin: 8px 0;
  border-radius: 4px;
}

#root .statTotalsExpansionPanel::before {
  opacity: 0;
}

#root .statTotalsHeading {
  flex-basis: 33.3%;
  flex-shrink: 0;
}

#root .statTotalsSecHeading {
  /* color: rgba(0, 0, 0, 0.54); */
}

#root .statTotalsExpansionDetails {
  flex-direction: column;
}

.diff.positive {
  color: #43a047;
}
.diff.negative {
  color: #e53935;
}
.diff.neutral {
  color: rgba(0, 0, 0, 0.54);
}

.teamsListContainer {
  display: none;
  height: calc(100% - 32px);
  margin: 16px;
  overflow: auto;
  flex-shrink: 0;
}

.teamImage > img {
  width: 112px;
  height: 112px;
}

.rosterTableContainer {
  min-width: 800px;
}

.rosterTableContainer .optimizationType {
  display: inline-block;
  padding: 8px;
}

.rosterTableContainer table {
  border-spacing: 0;
  text-align: center;
}

.rosterTableContainer table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.rosterTableBackground {
  overflow-y: auto;
  height: 600px;
}

.rosterTableItem {
  height: 50px;
}

.rosterTableItem.bench {
  background-color: #DDDDDD;
}

.rosterTableItem.moved td {
  font-weight: bold;
}

.rosterTableItem td.green {
  color: #43a047;
}

.rosterTableItem td.red {
  color: #e53935;
}

.playerInfoCell {
  display: flex;
}

.playerInfoCell .playerInfoImage {
  margin: 4px 0;
  position: static;
}

.playerInfoText {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
}

.playerInfoText .name {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.playerInfoText .posList {
  flex-grow: 1;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.54);
  align-items: flex-start;
}

#root .formControl {
  margin: 0px 0px 16px;
}

#root .datePicker {
  margin: 0px 16px 16px;
}

.content.unshift {
  margin-left: 73px;
  -webkit-transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.content.shift {
  margin-left: 275px;
}

.section {
    padding: 16px;
}
/* green - #43a047, yellow - #fdd835, red - #e53935 */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

.bareLink {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

.content {
  padding: 24px;
  flex-grow: 1;
}

.content .contentSpacer {
  min-height: 64px;
}

.loadingDialog .title {
  text-align: center;
}

.loadingDialog .progress {
  overflow: hidden;
  padding: 24px 48px 48px;
}

