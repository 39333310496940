/* green - #43a047, yellow - #fdd835, red - #e53935 */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

.bareLink {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

.content {
  padding: 24px;
  flex-grow: 1;
}

.content .contentSpacer {
  min-height: 64px;
}

.loadingDialog .title {
  text-align: center;
}

.loadingDialog .progress {
  overflow: hidden;
  padding: 24px 48px 48px;
}
