#root .drawer {
  height: 100%;
  width: 275px;
  flex-shrink: 0;
  overflow-x: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 2;
}

#root .drawer .drawerHead {
  min-height: 64px;
}

#root .drawer .teamListItem.selected {
  border-right: 4px solid #6001d2;
  /* background-color: rgba(0, 0, 0, 0.08); */
  /* background-color: rgba(96, 1, 210, 0.08);
  background-color: rgba(1, 121, 255, 0.08);
  background-color: rgba(152, 68, 255, 0.08); */
}

#root .drawer .teamListItem.disabled .teamListItemAvatar,
#root .drawer .teamListItem.disabled .teamListItemText {
  opacity: 0.5;
}

#root .drawer .teamListItemText {
  padding-right: 0;
}

#root .drawer .teamListItemText > p {
  /* max-width: 150px; */
}

#root .drawer .teamLinkButton {
  align-self: center;
  color: rgba(0, 0, 0, 0.54);
}

#root .drawer .teamListHeadingText {
  text-align: center;
}

#root .drawer .leagueYearText {
  color: rgba(0, 0, 0, 0.54);
}

#root .drawer .paper {
  width: 275px;
  position: relative;
  overflow-x: hidden;
}

#root .drawer .paper.close {
  white-space: nowrap;
}

#root .drawer.open {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 275px;
}

#root .drawer.close {
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow-x: hidden;
  width: 73px;
}
